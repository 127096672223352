import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPrediction_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPrediction_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsUserDisabled: any[];
  IsUserDisabled_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetOngoingPredictionsAsJson: any[];
  GetOngoingPredictionsAsJson_dummy: any[];
  GetGCPAuthToken: string;
  GetGCPAuthToken_dummy: string;
  CheckVertexAiCustomJobsStatus: string;
  CheckVertexAiCustomJobsStatus_dummy: string;
  UpdateOngoingPredictions: number;
  UpdateOngoingPredictions_dummy: number;
  GetPredictions: any[];
  GetPredictions_dummy: any[];
  GetPredictionCount: any[];
  GetPredictionCount_dummy: any[];
  GetStatusDropDown: any[];
  GetStatusDropDown_dummy: any[];
  GetConfigFromFile: any;
  GetConfigFromFile_dummy: any;
  DeletePrediction: number;
  DeletePrediction_dummy: number;
  CancelVertexAiCustomJob: any;
  CancelVertexAiCustomJob_dummy: any;
  UpdatePredictionAfterPredictionCancel: number;
  UpdatePredictionAfterPredictionCancel_dummy: number;
  UpdateModelOrPredictionAfterCancelFail: number;
  UpdateModelOrPredictionAfterCancelFail_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  GetProjectRequirementsAsJson: any[];
  GetProjectRequirementsAsJson_dummy: any[];
  SaveJsonToBucket: any;
  SaveJsonToBucket_dummy: any;
  GetVertexAiApiCompatibleTargetKey: string;
  GetVertexAiApiCompatibleTargetKey_dummy: string;
  GetModelBucketPath: any[];
  GetModelBucketPath_dummy: any[];
  CreateVertexAiCustomJob: any;
  CreateVertexAiCustomJob_dummy: any;
  UpdatePredictionAfterPredictionStart: number;
  UpdatePredictionAfterPredictionStart_dummy: number;
  isComp155052Enabled: "enabled" | "disabled";
  isComp485487Enabled: "enabled" | "disabled";
  isComp14934Enabled: "enabled" | "disabled";
  isComp252302Enabled: "enabled" | "disabled";
  isComp56837Enabled: "enabled" | "disabled";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Prediction_List_ScreenBase extends React.PureComponent<IPrediction_List_ScreenProps, any> {
  prediction_list_164601_value_kuikaSelectBoxRef: React.RefObject<any>;
  prediction_list_795226_value_kuikaTableRef: React.RefObject<any>;
  prediction_list_658618_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "63e32bab-26e8-4211-a614-2ce750c21a3f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 958134, PropertyName: "value", Value: "Büyüklük Tahminleme İşlemleri" },
        { Id: 551893, PropertyName: "label", Value: "Ekle" },
        { Id: 9603, PropertyName: "label", Value: "Yenile" },
        { Id: 945581, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 795226, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 758027, PropertyName: "title", Value: "Proje Adı" },
        { Id: 922220, PropertyName: "value", Value: "[datafield:projectname]" },
        { Id: 357944, PropertyName: "title", Value: "Proje Dili" },
        { Id: 470215, PropertyName: "value", Value: "[datafield:language]" },
        { Id: 143445, PropertyName: "title", Value: "Metrik" },
        { Id: 112340, PropertyName: "value", Value: "[datafield:metricname]" },
        { Id: 313383, PropertyName: "title", Value: "Baz Model" },
        { Id: 105826, PropertyName: "value", Value: "[datafield:modelname]" },
        { Id: 39612, PropertyName: "title", Value: "Durum" },
        { Id: 789441, PropertyName: "value", Value: "[datafield:statusname]" },
        { Id: 176956, PropertyName: "title", Value: "Başlangıç Tarihi" },
        { Id: 806157, PropertyName: "value", Value: "[datafield:starttime]" },
        { Id: 412285, PropertyName: "title", Value: "Bitiş Tarihi" },
        { Id: 977403, PropertyName: "value", Value: "[datafield:endtime]" },
        { Id: 372121, PropertyName: "value", Value: "Kayıt" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.prediction_list_164601_value_kuikaSelectBoxRef = React.createRef();
    this.prediction_list_945581_value_kuikaTextInputRef = React.createRef();
    this.prediction_list_795226_value_kuikaTableRef = React.createRef();
    this.prediction_list_658618_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsUserDisabled: [],
      NAVIGATE: "",
      MyContext: [],
      GetOngoingPredictionsAsJson: [],
      GetGCPAuthToken: "",
      CheckVertexAiCustomJobsStatus: "",
      UpdateOngoingPredictions: 0,
      GetPredictions: [],
      GetPredictionCount: [],
      GetStatusDropDown: [],
      GetConfigFromFile: "",
      DeletePrediction: 0,
      CancelVertexAiCustomJob: "",
      UpdatePredictionAfterPredictionCancel: 0,
      UpdateModelOrPredictionAfterCancelFail: 0,
      Notify: false,
      GetProjectRequirementsAsJson: [],
      SaveJsonToBucket: "",
      GetVertexAiApiCompatibleTargetKey: "",
      GetModelBucketPath: [],
      CreateVertexAiCustomJob: "",
      UpdatePredictionAfterPredictionStart: 0,
      isComp155052Enabled: "disabled",
      isComp485487Enabled: "disabled",
      isComp14934Enabled: "disabled",
      isComp252302Enabled: "disabled",
      isComp56837Enabled: "disabled",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("prediction_list", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.Prediction_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("prediction_list", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("prediction_list", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Prediction_ListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.Prediction_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  Prediction_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Prediction_List/Prediction_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsUserDisabled = result?.data.isUserDisabled;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsUserDisabled?.length > 0
            ? stateVars.IsUserDisabled[0].result
            : this.state.IsUserDisabled?.length > 0
            ? this.state.IsUserDisabled[0].result
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Prediction_ListPageInit2_,
        "Prediction_List",
        "DisabledAccount",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Prediction_ListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      CompanyId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAuthToken_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingPredictionsAsJson?.length > 0
            ? stateVars.GetOngoingPredictionsAsJson[0].ongoingPredictions
            : this.state.GetOngoingPredictionsAsJson?.length > 0
            ? this.state.GetOngoingPredictionsAsJson[0].ongoingPredictions
            : null
        ),
        "string"
      ),
      OngoingItemStatusResultsJson_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      CompanyID_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      CompanyID_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Prediction_List/Prediction_ListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetOngoingPredictionsAsJson = result?.data.getOngoingPredictionsAsJson;
    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingPredictions = result?.data.updateOngoingPredictions;
    stateVars.GetPredictions = result?.data.getPredictions;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );
    stateVars.GetStatusDropDown = result?.data.getStatusDropDown;

    formVars.prediction_list_164601_options = stateVars.GetStatusDropDown;
    stateVars.GetConfigFromFile = result?.data.getConfigFromFile;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_164601 = this.state.GetStatusDropDown;
    stateVars.dataSource_164601 = this.state.GetStatusDropDown;

    stateVars.dataSource_795226 = this.state.GetPredictions;
    stateVars.isComp155052Enabled =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "statusName"),
        "Bitti"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(this, "prediction_list_795226_value", "statusName"),
          "Devam Ediyor"
        )) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp485487Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp14934Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "statusName"),
        "Bitti"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp252302Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp56837Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Prediction_ListComponent_551893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "ProjectID",
      ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "PredictionID",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "RedirectToPredictions",
      ReactSystemFunctions.convertToTypeByName("False", "boolean")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Prediction_List",
      "Prediction_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "405037",
      null,
      null,
      "False",
      "800px",
      "vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_9603_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAuthToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingPredictionsAsJson?.length > 0
            ? stateVars.GetOngoingPredictionsAsJson[0].ongoingPredictions
            : this.state.GetOngoingPredictionsAsJson?.length > 0
            ? this.state.GetOngoingPredictionsAsJson[0].ongoingPredictions
            : null
        ),
        "string"
      ),
      OngoingItemStatusResultsJson_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      CompanyID_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      CompanyID_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_9603_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOngoingPredictionsAsJson = result?.data.getOngoingPredictionsAsJson;
    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingPredictions = result?.data.updateOngoingPredictions;
    stateVars.GetPredictions = result?.data.getPredictions;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_9603_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_9603_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_164601_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_164601_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );
    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_164601_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_164601_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_945581_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_945581_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );
    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_945581_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_945581_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_155052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "ProjectID",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", ".projectID"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "PredictionID",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", ".id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "RedirectToPredictions",
      ReactSystemFunctions.convertToTypeByName("False", "boolean")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Prediction_List",
      "Prediction_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "385032",
      null,
      null,
      "False",
      "800px",
      "vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_485487_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PredictionID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", ".id"),
        "Guid"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2bb3b34c_a6f0_a7a3_c60f_0fe2d3a9a067_confirmation",
        this.defaultML,
        "Silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_List/Prediction_ListComponent_485487_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeletePrediction = result?.data.deletePrediction;
      stateVars.GetPredictionCount = result?.data.getPredictionCount;
      formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetPredictionCount?.length > 0
          ? stateVars.GetPredictionCount[0].count
          : this.state.GetPredictionCount?.length > 0
          ? this.state.GetPredictionCount[0].count
          : null
      );
      stateVars.GetPredictions = result?.data.getPredictions;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_485487_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_485487_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0
        ? stateVars.GetPredictionCount[0].count
        : this.state.GetPredictionCount?.length > 0
        ? this.state.GetPredictionCount[0].count
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_14934_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Result",
      "PredictionId",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "id"),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Prediction_List",
      "Prediction_Result",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "759643",
      null,
      null,
      "true",
      "600px",
      "300px",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_252302_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_252302_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_252302_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_252302_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      customJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "vertexCustomJobName"),
        "string"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "65fdc116_ebac_68ac_8e2e_68808bfba84d_confirmation",
        this.defaultML,
        "Tahminleme işlemini iptal etmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-roboto"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_List/Prediction_ListComponent_252302_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CancelVertexAiCustomJob = result?.data.cancelVertexAiCustomJob;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_252302_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_252302_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PredictionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CancelVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_List/Prediction_ListComponent_252302_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdatePredictionAfterPredictionCancel = result?.data.updatePredictionAfterPredictionCancel;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_252302_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_252302_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_252302_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TableName_0: ReactSystemFunctions.convertToTypeByName("Prediction", "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CancelVertexAiCustomJob?.result),
        "error"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_List/Prediction_ListComponent_252302_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateModelOrPredictionAfterCancelFail = result?.data.updateModelOrPredictionAfterCancelFail;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_252302_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_252302_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_252302_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_252302_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_252302_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_252302_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e196669a_c45c_839d_2aa9_263d9a9a9934_notify",
        this.defaultML,
        "Büyüklük tahminleme işlemi iptal edildi."
      ),
      ReactSystemFunctions.convertToTypeByName("success", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("5", "number"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("0", "number")
    );

    stateVars.dataSource_795226 = this.state.GetPredictions;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_56837_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjectId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "projectID"),
        "Guid"
      ),
      jsonString_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetProjectRequirementsAsJson?.length > 0
            ? stateVars.GetProjectRequirementsAsJson[0].requirementsAsJson
            : this.state.GetProjectRequirementsAsJson?.length > 0
            ? this.state.GetProjectRequirementsAsJson[0].requirementsAsJson
            : null
        ),
        "string"
      ),
      gcpAccessToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      prefix_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "projectName"),
        "string"
      ),
      folderName_2: ReactSystemFunctions.convertToTypeByName("data", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_56837_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.GetProjectRequirementsAsJson = result?.data.getProjectRequirementsAsJson;
    stateVars.SaveJsonToBucket = result?.data.saveJsonToBucket;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_56837_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result), "error")
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      targetKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "metricName"),
        "string"
      ),
      ModelId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "modelId"),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_56837_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetVertexAiApiCompatibleTargetKey = result?.data.getVertexAiApiCompatibleTargetKey;
    stateVars.GetModelBucketPath = result?.data.getModelBucketPath;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_56837_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      task_0: ReactSystemFunctions.convertToTypeByName("pred", "string"),
      modelBucketPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetModelBucketPath?.length > 0
            ? stateVars.GetModelBucketPath[0].modelBucketPath
            : this.state.GetModelBucketPath?.length > 0
            ? this.state.GetModelBucketPath[0].modelBucketPath
            : null
        ),
        "string"
      ),
      jsonBucketPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.bucketPath),
        "string"
      ),
      targetKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetVertexAiApiCompatibleTargetKey),
        "string"
      ),
      jobId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "id"),
        "string"
      ),
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserID
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserID
            : null
        ),
        "string"
      ),
      modelTypeCode_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "language"),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_List/Prediction_ListComponent_56837_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CreateVertexAiCustomJob = result?.data.createVertexAiCustomJob;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      StartTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      VertexCustomJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.customJob?.name),
        "string"
      ),
      PredictionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_List/Prediction_ListComponent_56837_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdatePredictionAfterPredictionStart = result?.data.updatePredictionAfterPredictionStart;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "error"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "31f08d1a_98b1_c183_7fe0_31d46e50f35b_notify",
          this.defaultML,
          "Tahminleme işlemi başladı."
        ),
        ReactSystemFunctions.convertToTypeByName("success", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ListComponent_56837_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_56837_onClick7_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_56837_onClick8_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetPredictions;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_658618_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_658618_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ListComponent_658618_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_658618_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetPredictions;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 405037, 385032, 759643] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Prediction_ListPageInit();
    }
  }
}
