import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBenchmark_Prediction_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBenchmark_Prediction_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  GetBenchmarkModelsForDropdown: any[];
  GetBenchmarkModelsForDropdown_dummy: any[];
  GetBenchmarkPrediction: any[];
  GetBenchmarkPrediction_dummy: any[];
  GetBenchmarkModelMetrics: any[];
  GetBenchmarkModelMetrics_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  UpsertBenchmarkPrediction: any[];
  UpsertBenchmarkPrediction_dummy: any[];
  isComp586551Visible: "visible" | "hidden";
  isComp430453Visible: "visible" | "hidden";
  isComp852926Visible: "visible" | "hidden";
  isComp990948Visible: "visible" | "hidden";
  isComp330126Visible: "visible" | "hidden";
  isComp724117Visible: "visible" | "hidden";
  isComp396733Visible: "visible" | "hidden";
  isComp683322Visible: "visible" | "hidden";
  isComp67651Visible: "visible" | "hidden";
  isComp135850Visible: "visible" | "hidden";
  isComp68721Visible: "visible" | "hidden";
  isComp52305Visible: "visible" | "hidden";
  isComp284534Visible: "visible" | "hidden";
  isComp910586Enabled: "enabled" | "disabled";
}

export class Benchmark_Prediction_Form_ScreenBase extends React.PureComponent<
  IBenchmark_Prediction_Form_ScreenProps,
  any
> {
  benchmark_prediction_form_781972_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "96fb3ba4-c9d0-48ab-9e56-db64f95d4caa",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 586551, PropertyName: "value", Value: "Efor Kestirimi - Yeni" },
        { Id: 430453, PropertyName: "value", Value: "Efor Kestirimi - Güncelle" },
        { Id: 428735, PropertyName: "value", Value: "Efor Kestirim Adı" },
        { Id: 694187, PropertyName: "value", Value: "Model" },
        { Id: 781972, PropertyName: "placeholder", Value: "Model Seçin" },
        { Id: 443974, PropertyName: "value", Value: "Model Tipi" },
        { Id: 424136, PropertyName: "value", Value: "Cosmic" },
        { Id: 179890, PropertyName: "value", Value: "Entry" },
        { Id: 418598, PropertyName: "value", Value: "Read" },
        { Id: 176309, PropertyName: "value", Value: "Write" },
        { Id: 271997, PropertyName: "value", Value: "Exit" },
        { Id: 735155, PropertyName: "value", Value: "Toplam CFP" },
        { Id: 347577, PropertyName: "value", Value: "Event" },
        { Id: 257945, PropertyName: "value", Value: "Interaction" },
        { Id: 137545, PropertyName: "value", Value: "Communication" },
        { Id: 889315, PropertyName: "value", Value: "Process" },
        { Id: 501215, PropertyName: "value", Value: "Toplam EP" },
        { Id: 101100, PropertyName: "label", Value: "Kaydet" },
        { Id: 566552, PropertyName: "label", Value: "İptal" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.benchmark_prediction_form_962414_value_kuikaTextInputRef = React.createRef();
    this.benchmark_prediction_form_781972_value_kuikaSelectBoxRef = React.createRef();
    this.benchmark_prediction_form_910586_value_kuikaTextInputRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      GetBenchmarkModelsForDropdown: [],
      GetBenchmarkPrediction: [],
      GetBenchmarkModelMetrics: [],
      GoBack: "",
      SetValueOf: "",
      Notify: false,
      UpsertBenchmarkPrediction: [],
      isComp586551Visible: "hidden",
      isComp430453Visible: "hidden",
      isComp852926Visible: "hidden",
      isComp990948Visible: "hidden",
      isComp330126Visible: "hidden",
      isComp724117Visible: "hidden",
      isComp396733Visible: "hidden",
      isComp683322Visible: "hidden",
      isComp67651Visible: "hidden",
      isComp135850Visible: "hidden",
      isComp68721Visible: "hidden",
      isComp52305Visible: "hidden",
      isComp284534Visible: "hidden",
      isComp910586Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("benchmark_prediction_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Benchmark_Prediction_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("benchmark_prediction_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("benchmark_prediction_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Benchmark_Prediction_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      benchmark_prediction_form_962414_value: this.state.GetBenchmarkPrediction?.at?.(0)?.name ?? undefined,
      benchmark_prediction_form_781972_value: this.state.GetBenchmarkPrediction?.at?.(0)?.modelId ?? undefined,
      benchmark_prediction_form_910586_value: this.state.GetBenchmarkPrediction?.at?.(0)?.modelTypeName ?? undefined,
      benchmark_prediction_form_352275_value: this.state.GetBenchmarkPrediction?.at?.(0)?.cosmicEntry ?? undefined,
      benchmark_prediction_form_250575_value: this.state.GetBenchmarkPrediction?.at?.(0)?.cosmicRead ?? undefined,
      benchmark_prediction_form_658354_value: this.state.GetBenchmarkPrediction?.at?.(0)?.cosmicWrite ?? undefined,
      benchmark_prediction_form_766686_value: this.state.GetBenchmarkPrediction?.at?.(0)?.cosmicExit ?? undefined,
      benchmark_prediction_form_132229_value: this.state.GetBenchmarkPrediction?.at?.(0)?.cosmicTotalCfp ?? undefined,
      benchmark_prediction_form_67470_value: this.state.GetBenchmarkPrediction?.at?.(0)?.eventInteraction ?? undefined,
      benchmark_prediction_form_218930_value:
        this.state.GetBenchmarkPrediction?.at?.(0)?.eventCommunication ?? undefined,
      benchmark_prediction_form_526190_value: this.state.GetBenchmarkPrediction?.at?.(0)?.eventProcess ?? undefined,
      benchmark_prediction_form_609185_value: this.state.GetBenchmarkPrediction?.at?.(0)?.eventTotalEp ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  Benchmark_Prediction_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      CompanyId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_Form/Benchmark_Prediction_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetBenchmarkModelsForDropdown = result?.data.getBenchmarkModelsForDropdown;

    formVars.benchmark_prediction_form_781972_options = stateVars.GetBenchmarkModelsForDropdown;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      PredictionId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_Form/Benchmark_Prediction_FormPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetBenchmarkPrediction = result?.data.getBenchmarkPrediction;
      formVars.benchmark_prediction_form_962414_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].name
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].name
          : null
      );
      formVars.benchmark_prediction_form_781972_value =
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].modelId
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].modelId
          : null;
      formVars.benchmark_prediction_form_910586_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].modelTypeName
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].modelTypeName
          : null
      );
      formVars.benchmark_prediction_form_352275_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicEntry
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicEntry
          : null
      );
      formVars.benchmark_prediction_form_250575_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicRead
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicRead
          : null
      );
      formVars.benchmark_prediction_form_658354_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicWrite
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicWrite
          : null
      );
      formVars.benchmark_prediction_form_766686_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicExit
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicExit
          : null
      );
      formVars.benchmark_prediction_form_132229_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicTotalCfp
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicTotalCfp
          : null
      );
      formVars.benchmark_prediction_form_67470_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventInteraction
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventInteraction
          : null
      );
      formVars.benchmark_prediction_form_218930_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventCommunication
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventCommunication
          : null
      );
      formVars.benchmark_prediction_form_526190_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventProcess
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventProcess
          : null
      );
      formVars.benchmark_prediction_form_609185_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventTotalEp
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventTotalEp
          : null
      );
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      BenchmarkModelTypeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_prediction_form_781972_value",
            "value",
            "GetBenchmarkModelsForDropdown",
            "id",
            "typeId"
          )
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_Form/Benchmark_Prediction_FormPageInit2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetBenchmarkModelMetrics = result?.data.getBenchmarkModelMetrics;
      stateVars.isComp852926Visible =
        (ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicTotalCfp
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicTotalCfp
              : null
          ),
          "1"
        ) ||
          ReactSystemFunctions.isEqualTo(
            ReactSystemFunctions.value(
              this,
              stateVars.GetBenchmarkModelMetrics?.length > 0
                ? stateVars.GetBenchmarkModelMetrics[0].cosmicRead
                : this.state.GetBenchmarkModelMetrics?.length > 0
                ? this.state.GetBenchmarkModelMetrics[0].cosmicRead
                : null
            ),
            "1"
          )) === true
          ? "visible"
          : "hidden";
      stateVars.isComp990948Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicEntry
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicEntry
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp330126Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicRead
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicRead
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp724117Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicWrite
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicWrite
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp396733Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicExit
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicExit
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp683322Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicTotalCfp
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicTotalCfp
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp67651Visible =
        (ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].eventCommunication
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].eventCommunication
              : null
          ),
          "1"
        ) ||
          ReactSystemFunctions.isEqualTo(
            ReactSystemFunctions.value(
              this,
              stateVars.GetBenchmarkModelMetrics?.length > 0
                ? stateVars.GetBenchmarkModelMetrics[0].eventTotalEp
                : this.state.GetBenchmarkModelMetrics?.length > 0
                ? this.state.GetBenchmarkModelMetrics[0].eventTotalEp
                : null
            ),
            "1"
          )) === true
          ? "visible"
          : "hidden";
      stateVars.isComp135850Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].eventInteraction
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].eventInteraction
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp68721Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].eventCommunication
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].eventCommunication
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp52305Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].eventProcess
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].eventProcess
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp284534Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].eventTotalEp
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].eventTotalEp
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp586551Visible =
      ReactSystemFunctions.isEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430453Visible =
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_962414_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].name
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].name
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_781972_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].modelId
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].modelId
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_781972 = this.state.GetBenchmarkModelsForDropdown;
    stateVars.dataSource_781972 = this.state.GetBenchmarkModelsForDropdown;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit7_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_910586_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].modelTypeName
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].modelTypeName
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp852926Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : null
        ),
        "1"
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicRead
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicRead
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp990948Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicEntry
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicEntry
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit9_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_352275_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicEntry
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicEntry
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp330126Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicRead
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicRead
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_250575_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicRead
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicRead
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp724117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicWrite
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicWrite
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit13_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_658354_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicWrite
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicWrite
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp396733Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicExit
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicExit
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit15_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_766686_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicExit
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicExit
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp683322Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit17_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_132229_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].cosmicTotalCfp
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].cosmicTotalCfp
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp67651Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventCommunication
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventCommunication
            : null
        ),
        "1"
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].eventTotalEp
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].eventTotalEp
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135850Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventInteraction
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventInteraction
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit19_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_67470_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventInteraction
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventInteraction
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp68721Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventCommunication
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventCommunication
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit21_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_218930_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventCommunication
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventCommunication
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp52305Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventProcess
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventProcess
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit23_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_526190_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventProcess
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventProcess
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormPageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp284534Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventTotalEp
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventTotalEp
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormPageInit25_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormPageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        true
      )
    ) {
      formVars.benchmark_prediction_form_609185_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkPrediction?.length > 0
          ? stateVars.GetBenchmarkPrediction[0].eventTotalEp
          : this.state.GetBenchmarkPrediction?.length > 0
          ? this.state.GetBenchmarkPrediction[0].eventTotalEp
          : null
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Benchmark_Prediction_FormComponent_67194_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Prediction_FormComponent_781972_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("benchmark_prediction_form_910586_value", "string"),
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(
          this,
          "benchmark_prediction_form_781972_value",
          "value",
          "GetBenchmarkModelsForDropdown",
          "id",
          "typeName"
        )
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_FormComponent_781972_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormComponent_781972_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BenchmarkModelTypeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_prediction_form_781972_value",
            "value",
            "GetBenchmarkModelsForDropdown",
            "id",
            "typeId"
          )
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_Form/Benchmark_Prediction_FormComponent_781972_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetBenchmarkModelMetrics = result?.data.getBenchmarkModelMetrics;
    stateVars.isComp852926Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : null
        ),
        "1"
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].cosmicRead
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].cosmicRead
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp990948Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicEntry
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicEntry
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330126Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicRead
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicRead
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp724117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicWrite
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicWrite
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp396733Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicExit
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicExit
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp683322Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].cosmicTotalCfp
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp67651Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventCommunication
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventCommunication
            : null
        ),
        "1"
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.GetBenchmarkModelMetrics?.length > 0
              ? stateVars.GetBenchmarkModelMetrics[0].eventTotalEp
              : this.state.GetBenchmarkModelMetrics?.length > 0
              ? this.state.GetBenchmarkModelMetrics[0].eventTotalEp
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135850Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventInteraction
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventInteraction
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp68721Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventCommunication
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventCommunication
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52305Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventProcess
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventProcess
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp284534Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetBenchmarkModelMetrics?.length > 0
            ? stateVars.GetBenchmarkModelMetrics[0].eventTotalEp
            : this.state.GetBenchmarkModelMetrics?.length > 0
            ? this.state.GetBenchmarkModelMetrics[0].eventTotalEp
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Benchmark_Prediction_FormComponent_101100_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_prediction_form_781972_value",
            "value",
            "GetBenchmarkModelsForDropdown",
            "id",
            "id"
          )
        ),
        true
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "37854f3b_686c_5914_a88f_d04ee249815c_notify",
          this.defaultML,
          "Model ismi seçimi gerekli."
        ),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormComponent_101100_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormComponent_101100_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_FormComponent_101100_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_962414_value", "value", "", "", "")
        ),
        null
      ),
      message: "Efor kestirim adı gerekli.",
      formName: "benchmark_prediction_form_962414_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BenchmarkPredictionId ?? this.props.screenInputs.benchmarkpredictionid,
        "Guid"
      ),
      Name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_962414_value", "value", "", "", "")
        ),
        "string"
      ),
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      ModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_prediction_form_781972_value",
            "value",
            "GetBenchmarkModelsForDropdown",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      CosmicEntry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_352275_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_766686_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_250575_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_658354_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicTotalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_132229_value", "value", "", "", "")
        ),
        "number"
      ),
      EventCommunication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_218930_value", "value", "", "", "")
        ),
        "number"
      ),
      EventInteraction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_67470_value", "value", "", "", "")
        ),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_526190_value", "value", "", "", "")
        ),
        "number"
      ),
      EventTotalEp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_prediction_form_609185_value", "value", "", "", "")
        ),
        "number"
      ),
      CreatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      LastUpdatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_prediction_form_781972_value",
            "value",
            "GetBenchmarkModelsForDropdown",
            "id",
            "id"
          )
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_Form/Benchmark_Prediction_FormComponent_101100_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpsertBenchmarkPrediction = result?.data.upsertBenchmarkPrediction;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormComponent_101100_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_FormComponent_101100_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_FormComponent_101100_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_prediction_form_781972_value",
            "value",
            "GetBenchmarkModelsForDropdown",
            "id",
            "id"
          )
        ),
        true
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Benchmark_Prediction_FormComponent_566552_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
