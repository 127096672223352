import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProject_Copy_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProject_Copy_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetKUserID: any[];
  GetKUserID_dummy: any[];
  SaveRecord_1: number;
  SaveRecord_1_dummy: number;
  ProjectRequirementCopy: number;
  ProjectRequirementCopy_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GetProject: any[];
  GetProject_dummy: any[];
  isComp6098384Enabled: "enabled" | "disabled";
  isComp197494Enabled: "enabled" | "disabled";
}

export class Project_Copy_Form_ScreenBase extends React.PureComponent<IProject_Copy_Form_ScreenProps, any> {
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "7bc91cdd-caf5-4d88-993f-b54ddae2b5ac",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 839541, PropertyName: "label", Value: "Kaydet" },
        { Id: 464419, PropertyName: "label", Value: "İptal" },
        { Id: 432538, PropertyName: "value", Value: "Proje Kopyalama" },
        { Id: 885244, PropertyName: "value", Value: "Proje Kodu" },
        { Id: 253741, PropertyName: "value", Value: "Proje Adı" },
        { Id: 763885, PropertyName: "value", Value: "Yeni Proje Kodu" },
        { Id: 102999, PropertyName: "value", Value: "Yeni Proje Adı" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.project_copy_form_6098384_value_kuikaTextInputRef = React.createRef();
    this.project_copy_form_197494_value_kuikaTextInputRef = React.createRef();
    this.project_copy_form_516795_value_kuikaTextInputRef = React.createRef();
    this.project_copy_form_232565_value_kuikaTextInputRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetKUserID: [],
      SaveRecord_1: false,
      ProjectRequirementCopy: 0,
      GoBack: "",
      ChangeEnabledOf: "",
      GetProject: [],
      isComp6098384Enabled: "enabled",
      isComp197494Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("project_copy_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Project_Copy_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("project_copy_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("project_copy_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Project_Copy_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      project_copy_form_6098384_value: this.state.GetProject?.at?.(0)?.projectCode ?? undefined,
      project_copy_form_197494_value: this.state.GetProject?.at?.(0)?.projectName ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  Project_Copy_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp6098384Enabled", "string"),
      ReactSystemFunctions.convertToTypeByName("disabled", "string")
    );
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp197494Enabled", "string"),
      ReactSystemFunctions.convertToTypeByName("disabled", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_Copy_FormPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Project_Copy_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Copy_Form/Project_Copy_FormPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetProject = result?.data.getProject;
    formVars.project_copy_form_6098384_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectCode
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectCode
        : null
    );
    formVars.project_copy_form_197494_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectName
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectName
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_Copy_FormPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_Copy_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.project_copy_form_6098384_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectCode
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectCode
        : null
    );

    formVars.project_copy_form_197494_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectName
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectName
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Project_Copy_FormComponent_839541_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NewProjectID ?? this.props.screenInputs.newprojectid,
        "Guid"
      ),
      Communication_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].communication
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].communication
            : null
        ),
        "boolean"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CompanyID ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      CosmicExit_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].cosmicExit
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].cosmicExit
            : null
        ),
        "boolean"
      ),
      CosmicRead_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].cosmicRead
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].cosmicRead
            : null
        ),
        "boolean"
      ),
      CosmicWrite_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].cosmicWrite
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].cosmicWrite
            : null
        ),
        "boolean"
      ),
      CreateDatetime_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      Entry_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].entry
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].entry
            : null
        ),
        "boolean"
      ),
      EventProcess_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[stateVars.GetProject.length - 1].eventProcess
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[this.state.GetProject.length - 1].eventProcess
            : null
        ),
        "boolean"
      ),
      Interaction_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].interaction
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].interaction
            : null
        ),
        "boolean"
      ),
      LastUpdateDatetime_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      LastUpdateUserID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUserID?.length > 0
            ? stateVars.GetKUserID[0].id
            : this.state.GetKUserID?.length > 0
            ? this.state.GetKUserID[0].id
            : null
        ),
        "Guid"
      ),
      ProjectCode_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_copy_form_516795_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjectName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_copy_form_232565_value", "value", "", "", "")
        ),
        "string"
      ),
      StatusID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].statusID
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].statusID
            : null
        ),
        "Guid"
      ),
      TotalCFP_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].totalCFP
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].totalCFP
            : null
        ),
        "boolean"
      ),
      TotalEP_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].totalEP
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].totalEP
            : null
        ),
        "boolean"
      ),
      UserID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUserID?.length > 0
            ? stateVars.GetKUserID[0].id
            : this.state.GetKUserID?.length > 0
            ? this.state.GetKUserID[0].id
            : null
        ),
        "Guid"
      ),
      NewProjectID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NewProjectID ?? this.props.screenInputs.newprojectid,
        "Guid"
      ),
      SourceProjectID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Project_Copy_Form/Project_Copy_FormComponent_839541_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUserID = result?.data.getKUserID;
    stateVars.SaveRecord_1 = result?.data.saveRecord_1;
    stateVars.ProjectRequirementCopy = result?.data.projectRequirementCopy;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_Copy_FormComponent_839541_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_Copy_FormComponent_839541_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_Copy_FormComponent_464419_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_Copy_FormComponent_120216_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
