import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KRow,
  Label,
  LayoutHeader,
  LayoutLeft,
  Menu,
  MenuItem,
  SubMenu,
  VerticalStack
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SiskonMaster_ScreenBase } from "./siskonmaster-base";

import { Profil_Screen } from "../profil/profil";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [
  { key: "906689", screenName: "UserList", params: {}, isStartingScreen: false },
  { key: "178736", screenName: "Company_List", params: {}, isStartingScreen: false },
  { key: "950878", screenName: "UserListFront", params: {}, isStartingScreen: false },
  { key: "944105", screenName: "Company_List_Front", params: {}, isStartingScreen: false },
  { key: "63197", screenName: "Project_List", params: { ModalHeight: "" }, isStartingScreen: false },
  { key: "823411", screenName: "Model_Training_List", params: {}, isStartingScreen: false },
  { key: "588110", screenName: "Prediction_List", params: {}, isStartingScreen: false },
  { key: "795143", screenName: "Queue_List", params: {}, isStartingScreen: false },
  { key: "725768", screenName: "Benchmark_Project_List", params: {}, isStartingScreen: false },
  { key: "722607", screenName: "Benchmark_Model_List", params: {}, isStartingScreen: false },
  { key: "344983", screenName: "Benchmark_Prediction_List", params: {}, isStartingScreen: false }
];

class SiskonMaster_Screen extends SiskonMaster_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              ...this.props.initialValues,
              siskonmaster_801592_value: "d37fdc98-016b-4b66-ca95-08dd50b89145",
              siskonmaster_398213_value: "0a340ab9-d28b-41e5-603c-08dc4281d046",
              siskonmaster_21213_value: { collapsed: false, selectedKey: "" }
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <LayoutLeft
                  id="Minus12"
                  id="siskonmaster_layout_left"
                  layoutPosition={1}
                  style={
                    {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      width: "100px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="955535"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="658483"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <HorizontalStack
                        id="401670"
                        direction="horizontal"
                        size={12}
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="siskonmaster_801592_value">
                          <Image
                            id="801592"
                            zoomOnClick={false}
                            imageFit="fit"
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                height: "48px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Image>
                        </Form.Item>

                        <Form.Item className="kFormItem" name="siskonmaster_398213_value">
                          <Image
                            id="398213"
                            zoomOnClick={false}
                            imageFit="fit"
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 5,
                                height: "48px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Image>
                        </Form.Item>
                      </HorizontalStack>
                    </KCol>
                  </KRow>

                  <KRow
                    id="237729"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="736245"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Form.Item className="kFormItem" name="siskonmaster_21213_value" noStyle>
                        <Menu
                          id="21213"
                          menuMap={menuMap}
                          visibility={this.state.isCompsiskonmaster_21213AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsiskonmaster_21213AuthorizationVisible}
                          options={this.state.dataSource_21213}
                          mode="vertical"
                          theme="light"
                          primaryColor="rgba(17, 144, 205, 1)"
                          collapsible={true}
                          collapsedButtonColor="rgba(17, 144, 205, 1)"
                          hamburgerControl={false}
                          dataidfield=""
                          datatitlefield=""
                          dataparentidfield=""
                          datalinkfield=""
                          dataiconfield=""
                          dataorderfield=""
                          style={{ width: "275px" } as any}
                        >
                          <SubMenu
                            id="311411"
                            visibility={this.state.isComp311411Visible}
                            label={ReactSystemFunctions.translate(this.ml, 311411, "label", this.defaultML)}
                            icon="person_outline"
                            style={{} as any}
                          >
                            <MenuItem
                              id="906689"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_906689_onClick();
                              }}
                              showCursorPointer
                              key={906689}
                              label={ReactSystemFunctions.translate(this.ml, 906689, "label", this.defaultML)}
                              icon="person"
                              key={906689}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="178736"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_178736_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isCompsiskonmaster_178736AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompsiskonmaster_178736AuthorizationVisible}
                              key={178736}
                              label={ReactSystemFunctions.translate(this.ml, 178736, "label", this.defaultML)}
                              icon="business"
                              key={178736}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="950878"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_950878_onClick();
                              }}
                              showCursorPointer
                              visibility={
                                this.state.isComp950878Visible == "hidden" ||
                                this.state.isCompsiskonmaster_950878AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityByAuthorization={
                                this.state.isCompsiskonmaster_950878AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              key={950878}
                              label={ReactSystemFunctions.translate(this.ml, 950878, "label", this.defaultML)}
                              icon="person"
                              key={950878}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="944105"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_944105_onClick();
                              }}
                              showCursorPointer
                              visibility={
                                this.state.isComp944105Visible == "hidden" ||
                                this.state.isCompsiskonmaster_944105AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityByAuthorization={
                                this.state.isCompsiskonmaster_944105AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              key={944105}
                              label={ReactSystemFunctions.translate(this.ml, 944105, "label", this.defaultML)}
                              icon="business"
                              key={944105}
                              style={{} as any}
                            ></MenuItem>
                          </SubMenu>

                          <SubMenu
                            id="736044"
                            label={ReactSystemFunctions.translate(this.ml, 736044, "label", this.defaultML)}
                            icon="apps"
                            style={{} as any}
                          >
                            <MenuItem
                              id="63197"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_63197_onClick();
                              }}
                              showCursorPointer
                              key={63197}
                              label={ReactSystemFunctions.translate(this.ml, 63197, "label", this.defaultML)}
                              key={63197}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="823411"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_823411_onClick();
                              }}
                              showCursorPointer
                              key={823411}
                              label={ReactSystemFunctions.translate(this.ml, 823411, "label", this.defaultML)}
                              key={823411}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="588110"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_588110_onClick();
                              }}
                              showCursorPointer
                              key={588110}
                              label={ReactSystemFunctions.translate(this.ml, 588110, "label", this.defaultML)}
                              key={588110}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="795143"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_795143_onClick();
                              }}
                              showCursorPointer
                              key={795143}
                              label={ReactSystemFunctions.translate(this.ml, 795143, "label", this.defaultML)}
                              key={795143}
                              style={{} as any}
                            ></MenuItem>
                          </SubMenu>

                          <SubMenu
                            id="147193"
                            label={ReactSystemFunctions.translate(this.ml, 147193, "label", this.defaultML)}
                            icon="speed"
                            style={{} as any}
                          >
                            <MenuItem
                              id="725768"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_725768_onClick();
                              }}
                              showCursorPointer
                              key={725768}
                              label={ReactSystemFunctions.translate(this.ml, 725768, "label", this.defaultML)}
                              key={725768}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="722607"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_722607_onClick();
                              }}
                              showCursorPointer
                              key={722607}
                              label={ReactSystemFunctions.translate(this.ml, 722607, "label", this.defaultML)}
                              key={722607}
                              style={{} as any}
                            ></MenuItem>

                            <MenuItem
                              id="344983"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_344983_onClick();
                              }}
                              showCursorPointer
                              key={344983}
                              label={ReactSystemFunctions.translate(this.ml, 344983, "label", this.defaultML)}
                              key={344983}
                              style={{} as any}
                            ></MenuItem>
                          </SubMenu>
                        </Menu>
                      </Form.Item>
                    </KCol>
                  </KRow>
                </LayoutLeft>
                <div style={{ flex: "auto" }}>
                  <LayoutHeader
                    id="Minus10"
                    id="siskonmaster_layout_header"
                    layoutPosition={2}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        height: "60px",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="42808"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="253202"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-right",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="636813"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KContentMenu
                            id="161940"
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KContentMenuPanel
                              id="245147"
                              style={
                                {
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="170142"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <VerticalStack
                                  id="384908"
                                  direction="vertical"
                                  size={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 0,
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="35136"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.SiskonMasterComponent_35136_onClick();
                                    }}
                                    showCursorPointer
                                    value={this.state.MyContext?.at?.(0)?.userName ?? undefined}
                                    style={
                                      {
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="39731"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.SiskonMasterComponent_39731_onClick();
                                    }}
                                    showCursorPointer
                                    value={this.state.MyContext?.at?.(0)?.companyName ?? undefined}
                                    style={
                                      {
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "11px",
                                        color: "rgba(140, 140, 140, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <Icon
                                  id="993369"
                                  iconName="person"
                                  style={
                                    {
                                      borderTopLeftRadius: 0,
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "24px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </HorizontalStack>
                            </KContentMenuPanel>

                            <KContentMenuItem
                              id="360619"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_360619_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 360619, "label", this.defaultML)}
                              style={
                                {
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></KContentMenuItem>

                            <KContentMenuItem
                              id="626942"
                              label={ReactSystemFunctions.translate(this.ml, 626942, "label", this.defaultML)}
                              style={
                                {
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></KContentMenuItem>

                            <KContentMenuItem
                              id="779573"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SiskonMasterComponent_779573_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 779573, "label", this.defaultML)}
                              style={
                                {
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></KContentMenuItem>
                          </KContentMenu>
                        </HorizontalStack>
                      </KCol>
                    </KRow>
                  </LayoutHeader>
                  <div style={{ display: "flex" }}>
                    <div
                      id="masterRootDI"
                      style={{
                        width: "0px",
                        height: "calc(100vh - 60px)",
                        flex: "auto",
                        overflow: "hidden",
                        maxWidth: "100vw",
                        position: "relative"
                      }}
                    >
                      <>{this.props.children}</>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <KDrawer
              id="286338"
              placement="right"
              onClose={() => {
                this.setState({ NavVisible286338: false }, () => {
                  this.callCallbackFunction(286338);
                });
              }}
              visible={this.state.NavVisible286338}
              width="380px"
              height="100vh"
              push={true}
              children={
                this.state.NavVisible286338 && (
                  <Profil_Screen
                    onClose={() => {
                      this.setState({ NavVisible286338: false }, () => {
                        this.callCallbackFunction(286338);
                      });
                    }}
                    screenInputs={window.kuika.navigationScreenInputs}
                    navigator={this.props.navigator}
                    form={this.props.form}
                    context={this.props.context}
                  />
                )
              }
              style={
                {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "rgba(68, 68, 68, 1)"
                } as any
              }
            ></KDrawer>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withHistory(connect(mapStateToProps, mapDispatchToProps)(SiskonMaster_Screen))))
);
export { tmp as SiskonMaster_Screen };
//export default tmp;
//export { tmp as SiskonMaster_Screen };
